<template>
<div>
  <el-form>
    <el-form-item>
      <span>消息类型:</span>
      <el-radio v-model="type" :label="0">没有余额</el-radio>
      <el-radio v-model="type" :label="1">有余额足够抵扣</el-radio>
    </el-form-item>
    <el-form-item v-if="type == 0">
      <insertTextareaVue elId="cztx" :txtList="txtList" value="payment_reminder_content" :data="data"></insertTextareaVue>
    </el-form-item>
     <el-form-item v-if="type == 1">
      <insertTextareaVue elId="cztx" :txtList="txtList1" value="payment_reminder_content_foot" :data="data"></insertTextareaVue>
    </el-form-item>
    
    <el-form-item>
      <span>超过缴费时间</span>
      <el-input v-model.number="data.reminder_day" placeholder="输入数字" style="width: 113px;margin: 0 10px;"></el-input>
      <span>天发送欠费提醒通知</span>
    </el-form-item>
    <el-form-item>
      <div>
        <span>是否循环通知：</span>
        <el-radio v-model="data.circular_notification " :label="0">否</el-radio>
        <el-radio v-model="data.circular_notification " :label="1">是</el-radio>
      </div>
      <insertTextareaVue elId="qftx" :txtList="txtList" value="arrears_reminder_content" :data="data"></insertTextareaVue>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import insertTextareaVue from "../../../../components/insertTextarea.vue";
export default {
  components: {
    insertTextareaVue,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          reminder_day: "",
          circular_notification: 0,
          payment_reminder_content: '',
          arrears_reminder_content: ''
        };
      },
    },
  },
  data() {
    return {
       txtList: [
        {
          name: '商户姓名',
          value: 0
        },
        {
          name: '费用类型',
          value: 1
        },
        {
          name: '费用金额',
          value: 2
        }
      ],
      txtList1: [
        {
          name: '商户姓名',
          value: 0
        },
        {
          name: '费用类型',
          value: 1
        },
        {
          name: '费用金额',
          value: 2
        },
        {
          name: '余额',
          value: 3
        },
        {
          name: '剩余金额',
          value: 4
        }
      ],
      type:0
    };
  },
  methods: {},
};
</script>
<style lang='scss'>
</style>
