<template>
<div>
  <el-form label-position="right" :model="form" :rules="rules" ref="ruleForm" label-width="150px">
    <el-form-item label="活动时间:">
      <el-date-picker v-model="form.values.date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="changeDate" type="daterange" ></el-date-picker>
    </el-form-item>
    <el-form-item label="优惠折扣:" prop="values.ActivityDiscount">
      <el-input class="label-width" type="number" v-model="form.values.ActivityDiscount" placeholder="请输入0-1折扣率"></el-input>
    </el-form-item>
    <el-form-item label="活动图片:">
      <el-upload class="avatar-uploader" action="" :on-change="handlePreview" :show-file-list="false" accept="image/*">
        <img v-if="form.values.activityImage" :src="form.values.activityImage" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-form-item>
    <el-form-item label="状态:">
      <el-switch v-model="form.values.isActivity" active-text="开启" :active-value="1" :inactive-value="0" inactive-text="关闭"></el-switch>
    </el-form-item>
    <el-form-item label="参与活动费用:">
      <el-checkbox-group v-model="form.values.key">
        <el-checkbox label="40">基础费用费用</el-checkbox>
        <el-checkbox label="10">日常费用</el-checkbox>
        <el-checkbox label="39">其他费用</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
  </el-form>
  <div class="base">
    <MyButton @click="save('ruleForm')">保存</MyButton>
  </div>
</div>
</template>
<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入优惠折扣'));
      } else {
        if (this.form.values.ActivityDiscount > 1 || this.form.values.ActivityDiscount <0) {
          callback(new Error('请输入0-1折扣率'));
        }
        callback();
      }
    };
    return {
      form: {
        key: 'propertySetting',
        describe: '基础费用设置',
        values: {
          ActivityStartTime: '',
          ActivityEndTime: '',
          ActivityDiscount: "",
          isActivity: '',
          key: [],
          activityImage: '',
          date: []
        }
      },
      rules:{
        'values.ActivityDiscount':[
          { required: true, message: '请输入优惠折扣', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$request.HttpGet('/setting/edit', {
        key: 'propertySetting'
      }).then(res => {
        res.data.values.key = res.data.values.key.split(',');
        this.form = res.data;
      });
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.values.key = this.form.values.key.join(',');
          this.form.values.ActivityDiscount *=1;
          this.form.values.isActivity *=1;
          this.$request.HttpPost('/setting/add', this.form).then(res => {
            this.$common.notifySuccess(res.msg);
            this.form = {
              key: 'propertySetting',
              describe: '基础费用设置',
              values: {
                ActivityStartTime: '',
                ActivityEndTime: '',
                ActivityDiscount: "",
                isActivity: '',
                key: [],
                activityImage: '',
                date: []
              }
            };
            this.$emit('close', false);
          });
        }
      })

    },
    handlePreview(file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append('file', file.raw);
        this.$request.HttpPost('/system/upload/upImg', formData).then(res => {
          this.form.values.activityImage = process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
        });
      }
    },
    changeDate(e) {
      if (e) {
        this.form.values.ActivityStartTime = e[0];
        this.form.values.ActivityEndTime = e[1];
      } else {
        this.form.values.ActivityStartTime = '';
        this.form.values.ActivityEndTime = '';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
/deep/.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.label-width {
    width: 350px;
}
.base {
  text-align: right;
  margin-top: 30px;
}
</style>
