<template>
  <div class="public-box">
    <div class="list-box">
      <div class="item">
        <span>基础费用消息通知设置</span>
        <MyButton :accessId="76321" @click="rentmessageset">设置</MyButton>
      </div>
      <div class="item">
        <span>固定费用消息通知设置</span>
        <MyButton :accessId="86003" @click="fsrentmessageset">设置</MyButton>
      </div>
      <div class="item">
        <span>特殊事项减免设置</span>
        <MyButton :accessId="55628" @click="$router.push('/charge/jmsetting')"
          >设置</MyButton
        >
      </div>
      <div class="item">
        <span>优惠活动设置</span>
        <!-- <MyButton @click="activeVisible = true">设置</MyButton> -->
        <MyButton :accessId="4540" @click="$router.push('/charge/specialOffer')"
          >设置</MyButton
        >
      </div>
      <div class="item">
        <span>日常费用消息通知设置</span>
        <MyButton :accessId="94054" @click="daymessgeset">设置</MyButton>
      </div>
      <div class="item">
        <span>现金收款抹零设置</span>
        <MyButton :accessId="14766" @click="cashWipe">设置</MyButton>
      </div>
      <div class="item">
        <span>商户能否修改缴费金额</span>
        <MyButton :accessId="85096" @click="commercialClick">设置</MyButton>
      </div>
    </div>
    <!-- zujin xiaoxi  -->
    <!-- :disabled="(contractRentSetting.values.surplus_day!==0 && contractRentSetting.values.surplus_day  || !contractRentSetting.values.surplus_day <0) || (contractRentSetting.values.owe_day!==0 || contractRentSetting.values.owe_day>=0)" -->
    <Confirm
      ref="confirm"
      type="content"
      :disabled="
        !contractRentSetting.values.surplus_day ||
        !contractRentSetting.values.owe_day
      "
      @confirm="rensetConfirm('contractRentSetting')"
      class="confirmbox"
    >
      <template slot="content">
        <rentMessages :data="contractRentSetting.values"></rentMessages>
      </template>
    </Confirm>
    <Confirm ref="fsconfirm" type="content">
      <template slot="content">
        <fsrentchargeVue></fsrentchargeVue>
      </template>
    </Confirm>
    <!-- 非随基础费用缴费类消息通知设置 -->
    <Confirm
      ref="nonRentalPayment"
      type="content"
      :disabled="false"
      @confirm="rensetConfirm('contractNotRentSetting')"
      class="confirmbox"
    >
      <template slot="content">
        <nonRentalPayment
          :data="contractNotRentSetting.values"
        ></nonRentalPayment>
      </template>
    </Confirm>

    <!-- 优惠 -->
    <el-dialog
      title="优惠活动设置"
      :visible.sync="activeVisible"
      :close-on-click-modal="false"
    >
      <active
        v-if="activeVisible"
        @close="
          (type) => {
            activeVisible = type;
          }
        "
      ></active>
    </el-dialog>
    <!-- 日常消息 -->
    <Confirm
      ref="daymsgconfirm"
      type="content"
      :disabled="!dailyNotice.values.reminder_day"
      @confirm="rensetConfirm('dailyNotice')"
    >
      <template slot="content">
        <Daymessageset :data="dailyNotice.values"></Daymessageset>
      </template>
    </Confirm>
    <el-dialog
      title="现金收款抹零设置"
      :visible.sync="cashWipeVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="抹零设置：">
          <el-radio-group v-model="ruleForm.values.unit">
            <!-- <el-radio :label="1">分</el-radio> -->
            <el-radio :label="2">角</el-radio>
            <el-radio :label="3">元</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton @click="cashWipeConfirm">确 定</MyButton>
      </span>
    </el-dialog>
    <el-dialog
      title="商户能否修改缴费金额设置"
      :visible.sync="commercialVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        :model="commercialForm"
        ref="ruleForm"
        label-width="180px"
        class="demo-ruleForm"
      >
        <el-form-item label="商户能否修改缴费金额：">
          <el-radio-group v-model="commercialForm.values.is_change_pay">
            <el-radio :label="1">能</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton @click="commercialConfirm">确 定</MyButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Confirm from "../../../unit/components/confirm.vue";
import fsrentchargeVue from "./chargesetchild/fsrentcharge.vue";
import active from "./chargesetchild/activeset.vue";
import Daymessageset from "./chargesetchild/daymessageset.vue";
import rentMessages from "./chargesetchild/rentMessage.vue";
import nonRentalPayment from "./chargesetchild/nonRentalPayment.vue";
export default {
  data() {
    return {
      settingvisible: false,
      rentderateVisible: false,
      activeVisible: false,
      daymessageVisible: false,
      nonRentalPayment: false,
      cashWipeVisible: false,
      commercialVisible: false,
      contractRentSetting: {
        describe: "基础费用消息通知设置",
        key: "contractRentSetting",
        values: {
          surplus_day: "", //提前多少天通知
          owe_day: "",
          is_circulation: 0,
        },
      },
      contractNotRentSetting: {
        describe: "非随基础费用缴费类消息通知设置",
        key: "contractNotRentSetting",
        values: {
          owe_day: "",
          owe_content: "",
        },
      },
      dailyNotice: {
        describe: "日常费用消息通知设置",
        key: "dailyNotice",
        values: {
          reminder_day: "", //提前多少天通知
          circular_notification: 0, //是否循环通知,0默认，1循环: "",
        },
      },
      form: {
        key: "contractToSignSetting",
        describe: "线上合同消息设置",
        values: {
          content: "",
        },
      },
      commercialForm: {
        key: "isChangePay",
        describe: "商户能否修改缴费金额设置",
        values: {
          is_change_pay: "",
        },
      },
      txtList: [
        {
          name: "商户姓名",
          value: 0,
        },
        {
          name: "缴费时间",
          value: 1,
        },
        {
          name: "缴费金额",
          value: 2,
        },
        {
          name: "费用名称",
          value: 3,
        },
      ],
      content: "",
      ruleForm: {
        key: "wipeZero",
        describe: "抹零设置",
        values: {
          unit: "",
        },
      },
    };
  },
  methods: {
    commercialClick() {
      this.$request
        .HttpGet("/setting/edit", {
          key: "isChangePay",
        })
        .then((res) => {
          if (res.data) {
            this.commercialForm = res.data;
          }
          this.commercialVisible = true;
        });
    },
    commercialConfirm() {
      this.$request
        .HttpPost("/setting/add", this.commercialForm)
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.commercialVisible = false;
        });
    },
    cashWipe() {
      this.$request
        .HttpGet("/setting/edit", {
          key: "wipeZero",
        })
        .then((res) => {
          if (res.data) {
            this.ruleForm = res.data;
          }
          this.cashWipeVisible = true;
        });
    },
    cashWipeConfirm() {
      this.$request.HttpPost("/setting/add", this.ruleForm).then((res) => {
        this.$common.notifySuccess(res.msg);
        this.cashWipeVisible = false;
      });
    },
    async getDetail(type) {
      let res = await this.$request.HttpGet(`/setting/edit?key=${type}`);
      if (res.data && res.data.values) {
        this[type] = res.data;
      }
    },
    rensetConfirm(type) {
      this.$request.HttpPost("/setting/add", this[type]).then((res) => {
        this.$common.notifySuccess(res.msg);
      });
    },
    confirmset() {
      this.$refs.setForm.validate();
    },
    async rentmessageset() {
      await this.getDetail("contractRentSetting");
      this.$refs.confirm.title = "基础费用消息通知设置";
      this.$refs.confirm.fade();
    },
    async fsrentmessageset() {
      await this.getDetail("contractNotRentSetting");
      this.$refs.nonRentalPayment.title = "固定费用消息通知设置";
      this.$refs.nonRentalPayment.fade();
    },
    async daymessgeset() {
      await this.getDetail("dailyNotice");
      this.$refs.daymsgconfirm.title = "日常费用消息通知设置";
      this.$refs.daymsgconfirm.fade();
    },
    mlsetting(e) {
      if (e == 1) {
        this.mlset = "元";
      } else if (e == 2) {
        this.mlset = "角";
      } else {
        this.mlset = "分";
      }
    },
  },
  components: {
    Confirm,
    fsrentchargeVue,
    active,
    Daymessageset,
    rentMessages,
    nonRentalPayment,
  },
};
</script>
<style lang="scss" scoped>
.list-box {
  width: 750px;
  // height: 208px;
  background: #ffffff;
  border: 1px solid #dedede;
  .item {
    width: 100%;
    height: 52px;
    padding: 0 60px 0 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    &:last-child {
      border-bottom: none;
    }
  }
}
.rentmessage {
  width: 520px;
  height: 240px;
  background: #ffffff;
}
.confirm-box {
  /deep/ .box {
    width: 880px;
  }
}
</style>
