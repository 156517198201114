<template>
  <div class="fsrent">
    <div class="time">
      <span>超过缴费时间</span>
      <el-input
        v-model="form.day"
        placeholder="输入数字"
        style="width: 113px; margin-left: 5px"
      ></el-input>
      <span style="margin-left: 14px">天发送欠费提醒通知</span>
    </div>
    <insertTxtarea
      elId="fszj"
      :data.sync="form"
      @getResult="getValue"
    ></insertTxtarea>
  </div>
</template>
<script>
import insertTxtarea from "../../../../components/insertTextarea.vue";
export default {
  props:{
  data:{
    type:Object,
    default:()=>{
      return {
        
      }
    }
  }
  },
  components: {
    insertTxtarea,
  },
  data() {
    return {
 
    };
  },
  methods: {

  },
};
</script>
<style lang="scss">
.fsrent {
  // height: 900px;
  // background: red;
  .edit-content {
    margin-top: 15px;
    .title {
      height: 32px;
      line-height: 32px;
      margin-bottom: 15px;
    }
  }
}
</style>