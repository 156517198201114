<template>
<div>
  <el-form>
    <el-form-item>
      <span>超过缴费时间</span>
      <el-input v-model.number="data.owe_day" placeholder="输入数字" style="width: 113px;margin: 0 10px;"></el-input>
      <span>天发送欠费提醒通知</span>
    </el-form-item>
    <el-form-item>
      <insertTextareaVue elId="changeMessage" value="owe_content" :txtList="txtList" :data="data"></insertTextareaVue>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import insertTextareaVue from "../../../../components/insertTextarea.vue";
export default {
  components: {
    insertTextareaVue,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          owe_day: "",
          owe_content: '',
        };
      },
    },
  },
  data() {
    return {
      txtList: [
        {
          name: '商户姓名',
          value: 0
        },
        {
          name: '缴费时间',
          value: 1
        },
        {
          name: '缴费金额',
          value: 2
        },
        {
          name: '费用名称',
          value: 3
        }
      ]
    };
  },
  methods: {},
};
</script>
<style lang='scss'>
</style>
