<template>
<div>
  <el-form>
    <el-form-item>
      <span>缴费时间前</span>
      <el-input v-model.number="data.surplus_day" placeholder="输入数字" style="width: 113px;margin: 0 10px;"></el-input>
      <span>天发送缴费提醒通知</span>
    </el-form-item>
    <el-form-item>
      <span>消息类型：</span>
      <el-radio v-model="type" :label="0">没有余额</el-radio>
      <el-radio v-model="type" :label="1">有余额不够抵扣</el-radio>
      <el-radio v-model="type" :label="2">余额充足</el-radio>
    </el-form-item>
    <!-- 余额不足 -->
    <el-form-item v-if="type == 0">
      <insertTextareaVue elId="changeMessage" :txtList="txtList" value="surplus_content" :data="data"></insertTextareaVue>
    </el-form-item>
    <!-- :有余额不足抵扣 -->
     <el-form-item v-if="type == 1">
      <insertTextareaVue elId="changeMessage" :txtList="txtList1" value="surplus_content_have_foot" :data="data"></insertTextareaVue>
    </el-form-item>
    <!-- :余额充足 -->
     <el-form-item v-if="type == 2">
      <insertTextareaVue elId="changeMessage" :txtList="txtList" value="surplus_content_foot" :data="data"></insertTextareaVue>
    </el-form-item>
    <el-form-item>
      <span>超过缴费时间</span>
      <el-input v-model.number="data.owe_day" placeholder="输入数字" style="width: 113px;margin: 0 10px;"></el-input>
      <span>天发送欠费提醒通知</span>
    </el-form-item>
    <el-form-item>
      <div>
        <span>是否循环通知：</span>
        <el-radio v-model="data.is_circulation " :label="0">否</el-radio>
        <el-radio v-model="data.is_circulation " :label="1">是</el-radio>
      </div>
      <insertTextareaVue elId="oweMessage" :txtList="txtList2" value="owe_content" :data="data"></insertTextareaVue>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import insertTextareaVue from "../../../../components/insertTextarea.vue";
export default {
  components: {
    insertTextareaVue,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          surplus_day: "",
          owe_day: "",
          surplus_content: '',
          owe_content: '',
        };
      },
    },
  },
  data() {
    return {
      txtList: [
        {
          name: '商户姓名',
          value: 0
        },
        {
          name: '缴费时间',
          value: 1
        },
        {
          name: '缴费金额',
          value: 2
        }
      ],
      txtList1:[
        {
          name: '商户姓名',
          value: 0
        },
        {
          name: '缴费时间',
          value: 1
        },
        {
          name: '缴费金额',
          value: 2
        },
         {
          name: '余额',
          value: 3
        },
        {
          name: '剩余应缴金额',
          value: 4
        }
      ],
       txtList2:[
        {
          name: '商户姓名',
          value: 0
        },
        {
          name: '缴费时间',
          value: 1
        },
        {
          name: '缴费金额',
          value: 2
        },
         {
          name: '余额',
          value: 3
        },
        {
          name: '剩余金额',
          value: 4
        }
      ],
      type:0 //类型 0不足1
    };
  },
  methods: {},
};
</script>
<style lang='scss'>
</style>
